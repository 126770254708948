import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import ContactForm from "../components/contactForm"
import { Box, Heading, Text, useMediaQuery } from "@chakra-ui/react"

const IndexPage = () => {
  const [isLowerThan480] = useMediaQuery("(max-width: 480px)")
  return (
    <Layout>
      <Box pt={20} pb={10} marginTop={isLowerThan480 ? "100px" : "0"}>
        <Text
          as="h2"
          fontWeight="700"
          fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          my={12}
          textAlign={"center"}
        >
          Contact Us
        </Text>
        <ContactForm />
      </Box>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
